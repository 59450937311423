<template>
  <div class="pt-6 px-4">
    <div class="w-full grid grid-cols-12 gap-4 pb-6">
      <div class="col-span-5 session-into">
        <div class="rounded-lg meeting-sp relative">
          <img class="w-full p-6 sm:p-6 xl:p-8 bg-white shadow" src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=150" />
          <div class="w-full overfow-heading bg-white shadow rounded-lg p-5 absolute session-details">
            <div class="grid grid-cols-12">
              <div class="col-span-10">
                <h3>Meeting with Jake Alberta</h3>
              </div>
              <div class="col-span-2">
                <p class="date">08<span>APRIL</span></p>
              </div>
            </div>
            <div class="grid grid-cols-12">
              <div class="col-span-8">
                <p>CERTIFICATE UX/UI design</p>
                <p>Begin in 15 mins</p>
              </div>
              <div class="col-span-4">
                <p class="date">Join Now</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-7">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 meeting-sp">
          <h2 class="pb-5">Upcoming Sessions</h2>
          <div class="grid grid-cols-12 pb-8">
            <div class="col-span-2">
              <p>Date</p>
            </div>
            <div class="col-span-6">
              <p class="date">Name of the client</p>
            </div>
            <div class="col-span-4">
              <p class="date">Time</p>
            </div>
          </div>

          <div v-for="(schedule, idx) in calendar" :key="idx" class="grid grid-cols-12 pb-5">
            <div class="col-span-2 text-center">
              <span>{{ schedule.date }}</span>
            </div>
            <div v-for="(session, idx) in schedule.sessions" :key="idx" class="col-span-10 shadow rounded-lg p-5">
              <div class="grid grid-cols-12">
                <div class="col-span-7">
                  <p class="">{{ session.client }}</p>
                  <span class="bg-white">{{ session.tagline }}</span>
                </div>
                <div class="col-span-5">
                  <p class="">{{ session.time }}</p>
                </div>
              </div>
            </div>
          </div>
          <a href="" class="see-all">See All Sessions</a>
        </div>
      </div>
    </div>

    <div class="w-full grid gap-4 pb-6 account-summery mt-12">
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
        <h2 class="pb-8">Recomendations</h2>
        <div>
          <div class="inline-block mb-6 rounded-full pr-5 h-16 line-height-username1">
            <img class="rounded-full float-left h-full" src="https://randomuser.me/api/portraits/women/34.jpg" /> <span class="ml-3">Kate Horwitz</span>
          </div>
          <p class="pb-5">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.”</p>
        </div>
        <div>
          <div class="inline-block mb-6 rounded-full pr-5 h-16 line-height-username1">
            <img class="rounded-full float-left h-full" src="https://randomuser.me/api/portraits/women/34.jpg" /> <span class="ml-3">Kate Horwitz</span>
          </div>
          <p class="pb-5">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.”</p>
        </div>
        <div class="load-more w-full text-center p-7">See all recomendations</div>
      </div>
    </div>
  </div>
</template>

<script>
// import RatingBar from '@/components/RatingBar.vue';

export default {
  name: 'consultant_summary',
  components: {
    // 'rating-bar': RatingBar,
  },
  data() {
    return {
      clients: 5,
      rating: 5,
      upcomingSessions: [
        { date: 'March 12, 2022', client: 'Bruce Wayne', tagline: 'Batman', time: '8:30am - 1:00pm' },
        { date: 'March 12, 2022', client: 'Bruce Banner', tagline: 'Hulk', time: '1:30pm - 5:00pm' },
        { date: 'March 13, 2022', client: 'Steve Rogers', tagline: 'Captain America', time: '8:30am - 1:00pm' },
        { date: 'March 13, 2022', client: 'Buckey Barns', tagline: 'Winter Soldier', time: '1:30pm - 5:00pm' },
        { date: 'March 14, 2022', client: 'Peter Parker', tagline: 'Spider Man', time: '8:30am - 1:00pm' },
        { date: 'March 14, 2022', client: 'Tony Stark', tagline: 'Iron Man', time: '1:30pm - 5:00pm' },
      ],
      completedMeetings: 12,
    };
  },
  computed: {
    calendar() {
      let dates = [];
      this.upcomingSessions.forEach((session) => {
        let schedule = dates.find((item) => item.date === session.date);
        if (schedule === undefined) {
          dates.push({ date: session.date, sessions: [session] });
        } else {
          schedule.sessions.push(session);
        }
      });
      return dates;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
